<script setup>
import {reactive} from "vue";

const state = reactive({
    isFooterExpanded: false
})
</script>

<template>
    <footer>
        <div class="footerwrapper">
            <div class="top">
                <div class="logo">
                    <img src="/images/spinza-logo.svg" alt="Spinza Logo" />
                </div>
                <div class="links">
                    <nav aria-label="casino-menu">
                        <h4>Casino</h4>
                        <router-link :to="{name: 'webplay.live-casino'}">Live Casino</router-link>
                        <router-link :to="{name: 'promotions'}">Promotions</router-link>
                    </nav>
                    <nav aria-label="company-menu">
                        <h4>Company</h4>
                        <router-link :to="{name: 'how-to'}">How To</router-link>
                        <router-link :to="{name: 'about-us'}">About Us</router-link>
                        <a href="mailto:support@spinza.co.za">Contact Us</a>
                    </nav>
                    <nav aria-label="resources-menu">
                        <h4>Resources</h4>
                        <router-link :to="{ name: 'terms-and-conditions' }">Terms</router-link>
                        <router-link :to="{ name: 'privacy-policy' }">Privacy Policy</router-link>
                        <router-link :to="{ name: 'responsible-gambling' }">Responsible Gaming</router-link>
                    </nav>
                </div>
            </div>
            <div class="middle">
                <div class="content" :class="{ 'show': state.isFooterExpanded }" :style="{ 'height': state.isFooterExpanded ? '281px' : '150px' }">
                    <p><router-link class="homeRouterLink" :to="{ name: 'home' }">Spinza.co.za</router-link> features all of the thrills of the greatest land-based casino. The best games selections in casino gaming from the comfort of your own device, seven days a week. We have all of the games you'd expect from a first-rate casino in South Africa, as well as easy banking, superb customer service, and fantastic promotions.</p>
                    <p>There's little chance you'll ever get bored with 350 different slots titles spanning both regular and progressive jackpots. Fresh titles are introduced on a regular basis, so you'll never be bored.</p>
                    <p><router-link class="homeRouterLink" :to="{ name: 'home' }">Spinza.co.za</router-link>provides slots, video poker, standard or progressive video keno games, and a variety of scratch cards with massive jackpots.
                        Table game fans may choose from a number of blackjack, roulette, casino poker, and baccarat versions. Credit cards, bank wires, cryptocurrency, money orders, and person-to-person transfers are all options for deposit transactions. We reward players with a variety of promotions in addition to our extensive banking choices.</p>
                    <button id="btnJoinNowFooter" @click="authStore.setAuthModalVisibility(true, 'registration')">JOIN NOW</button>
                </div>
                <span class="mask"></span>
                <button class="toggler" @click="state.isFooterExpanded = !state.isFooterExpanded">
                    <span></span><span></span><span></span>
                </button>
            </div>
        </div>
        <div class="banking">
            <img src="https://assets.spinza.co.za/banking/dep-zar-white.svg" class="depositmethods" alt="Deposit methods"/>
        </div>
        <div class="licenses">
            <a href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbkZsY2pGMlUwbHFkMEpFY0ROdFRWWmlhWFpWUzFFOVBTSXNJblpoYkhWbElqb2lMMWxDTUZoVmJGRTFjelkyTUN0UGVWTjNhM2hqWnowOUlpd2liV0ZqSWpvaU56ZzJaRGd5WVRNNU0yUmpaRFkzTVRGaU5EUXdOell6TWpOalpUY3pOV0kzTnpFMVltTmlPV1l4TjJJMFlUbGpOREEwTTJNd05UQXhZbU00TnpsaU5TSXNJblJoWnlJNklpSjk=" target="_blank">
                <img src="/images/licenses/GCB-license.svg" alt="GCB License"/>
            </a>
        </div>
        <div class="bottom">
            <div class="over18">+18</div>
            <div class="copyright">© 2025 Spinza All rights reserved. www.spinza.co.za is operated by Gleneagles B.V., Curacao company registration number 158256 at, Johan Van WalbeeckPlein 24, Curacao</div>
        </div>
    </footer>
</template>

<style scoped>
    .homeRouterLink {
        color: #007a4d;
        text-decoration: none;
    }

    .licenses {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em;
        background-color: #171c25;

        img {
            width: 100px;
            height: auto;
        }
    }
</style>
